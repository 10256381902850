<i18n>
{
  "de": {
    "meterValuesTitle": "Verbrauchsdatenwerte erfassen",
    "noData": "Für diesen Datenpunkt wurden noch keine Verbrauchsdaten erfasst.",
    "pendingMessage": "Daten werden gespeichert...",
    "yearTitle": "Jahr",
    "amountTitle": "Endenergie",
    "amountNormalizedTitle": "Mittelwert Endenergie (witterungsbereinigt)",
    "ghgEmissionsS1Title": "Scope 1 Emissionen",
    "ghgEmissionsTitle": "Scope 1-2 Emissionen",
    "peDemandTitle": "Primärenergie",
    "addButton": "Wert hinzufügen",
    "closeAddButton": "Abbrechen",
    "closeEditButton": "Abbrechen",
    "deleteConfirmation": "Wollen Sie diesen Wert wirklich löschen?",
    "averageTitle": "Mittelwert",
    "yearExistsError": "Dieses Jahr existiert bereits",
    "normalizationTitle": "Witterungsbereinigung",
    "normalizationSource": "Methode: HGT20/12 (HEV Schweiz / MeteoSchweiz)",
    "normalizationStation": "Klimastation",
    "normalizationAltitudeLabel": "Höhe",
    "normalizationTemperatureLabel": "Normtemperatur"
  }
}
</i18n>

<template>
  <div class="c-meter-values-list">
    <div class="title">
      <h2>{{ $t('meterValuesTitle') }}</h2>
      <ButtonWrapper>
        <Button
          v-if="getBuildingPermission('EDIT_METERS')"
          :disabled="pending || editing"
          icon="plus"
          :text="$t('addButton')"
          @click="onShowAdd"
        />
      </ButtonWrapper>
    </div>

    <div v-if="pending">
      <p>{{ $t('pendingMessage') }}</p>
    </div>

    <Card v-if="!Boolean(meterValues.length) && !isAdding">
      <NoDataMessage :message="$t('noData')" />
    </Card>

    <DetailList v-else :has-footer="meterValues.length > 0" has-header class="meter-values-table">
      <template #header>
        <span>{{ $t('yearTitle') }}</span>
        <span class="align-right">{{ $t('amountTitle') }}</span>
        <span>
          <span class="align-right">{{ $t('amountNormalizedTitle') }}</span>
          <InfoBox :title="$t('normalizationTitle')">
            {{ $t('normalizationSource') }}<br />
            {{ $t('normalizationStation') }}: {{ climateStationName }}<br />
            {{ $t('normalizationAltitudeLabel') }}: {{ climateStationAltitude }}<br />
            {{ $t('normalizationTemperatureLabel') }}: {{ climateStationNormTemp }}
          </InfoBox>
        </span>
        <span class="align-right">{{ $t('ghgEmissionsS1Title') }}</span>
        <span class="align-right">{{ $t('ghgEmissionsTitle') }}</span>
        <span class="align-right">{{ $t('peDemandTitle') }}</span>
        <div></div>
      </template>

      <li v-for="(v, index) in meterValues" :key="index" :class="{ 'align-top': editIndex === index && !isValid }">
        <NumericInput
          v-if="editIndex === index"
          v-model="editYear"
          noFormatting
          int
          :min="minYear"
          :max="currentYear"
          :invalidValues="usedMeterYears"
          :invalidValuesErrorMessage="$t('yearExistsError')"
          :edit="true"
        />
        <div v-else>{{ v.year }}</div>
        <div class="align-right">
          <NumericInput v-if="editIndex === index" v-model="editAmount" :edit="true" :units="'kWh'" />
          <div v-else>{{ formatNumber(v.amount) }} kWh</div>
        </div>
        <div v-if="meter.meter_type === 'END_ENERGY_HEATER' && editIndex !== index" class="align-right">
          {{ formatNumber(v.amount_normalized) }} kWh
        </div>
        <div v-else class="align-right">-</div>
        <div v-if="editIndex !== index" class="align-right">{{ formatNumber(v.s1e, 0) }} kg CO₂e</div>
        <div v-else class="align-right">-</div>
        <div v-if="editIndex !== index" class="align-right">{{ formatNumber(v.s12e, 0) }} kg CO₂e</div>
        <div v-else class="align-right">-</div>
        <div v-if="editIndex !== index" class="align-right">{{ formatNumber(v.pe) }} kWh</div>
        <div v-else class="align-right">-</div>
        <ListButtonWrapper>
          <ListSaveButton v-if="editIndex === index" :disabled="!isValid" @click="onSave(index)" />
          <ListEditButton v-if="!editing && getBuildingPermission('EDIT_METERS')" @click="onEdit(index)" />
          <ListDeleteButton v-if="!editing && getBuildingPermission('EDIT_METERS')" @click="onDelete(index)" />
          <ListButton
            v-if="editIndex === index"
            :img-src="'/icons/x-circle.svg'"
            :label="$t('closeEditButton')"
            @click="onCloseEdit"
          />
        </ListButtonWrapper>
      </li>

      <li v-if="isAdding" :class="{ 'align-top': !isValid }">
        <NumericInput
          v-model="editYear"
          noFormatting
          int
          :min="minYear"
          :max="currentYear"
          :invalidValues="usedMeterYears"
          :invalidValuesErrorMessage="$t('yearExistsError')"
          :edit="true"
        />
        <NumericInput v-model="editAmount" :edit="true" :units="'kWh'" />
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <ListButtonWrapper>
          <ListSaveButton :disabled="!isValid" @click="onAdd" />
          <ListButton :img-src="'/icons/x-circle.svg'" :label="$t('closeAddButton')" @click="onCloseEdit" />
        </ListButtonWrapper>
      </li>

      <template #footer>
        <span>{{ $t('averageTitle') }}</span>
        <span class="align-right">{{ formatNumber(averageAmount, 0) }} kWh</span>
        <span v-if="meter.meter_type === 'END_ENERGY_HEATER'" class="align-right"
          >{{ formatNumber(averageAmountNormalized, 0) }} kWh</span
        >
        <span v-else></span>
        <span class="align-right">{{ formatNumber(averageGhgS1, 0) }} kg CO₂e</span>
        <span class="align-right">{{ formatNumber(averageGhg, 0) }} kg CO₂e</span>
        <span class="align-right">{{ formatNumber(averagePe, 0) }} kWh</span>
        <div></div>
      </template>
    </DetailList>

    <ListActionFeedback :message="error" @close="error = null" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListButton from '@/components/shared/lists/ListButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListSaveButton from '@/components/shared/lists/ListSaveButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ListActionFeedback from '@/components/shared/lists/ListActionFeedback.vue'
import NoDataMessage from '@/components/shared/lists/NoDataMessage.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import Button from '@/components/cui/inputs/Button.vue'
import Card from '@/components/cui/surfaces/Card.vue'

export default {
  components: {
    ListButton,
    ListButtonWrapper,
    ListEditButton,
    ListSaveButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    ListActionFeedback,
    NoDataMessage,
    NumericInput,
    InfoBox,
    Button,
    Card,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    meter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editing: false,
      error: null,
      pending: false,
      editIndex: null,
      editYear: null,
      editAmount: null,
      minYear: 2000,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    isValid() {
      return (
        typeof this.editYear === 'number' &&
        typeof this.editAmount === 'number' &&
        this.editYear >= this.minYear &&
        this.editYear <= this.currentYear &&
        !this.usedMeterYears.includes(this.editYear)
      )
    },

    isAdding() {
      return this.editing && this.editIndex === null
    },

    currentYear() {
      return new Date().getFullYear()
    },

    // Meter values sorted by year
    meterValues() {
      return _.sortBy(this.meter.values || [], 'year')
    },

    meterYears() {
      return this.meterValues.map((v) => v.year)
    },

    // Years array used on the list for duplicates check
    usedMeterYears() {
      const years = _.clone(this.meterYears)

      // Omit the year being edited
      years.splice(this.editIndex, 1)
      return years
    },

    averageAmount() {
      const sum = this.meterValues.reduce((pv, cv) => pv + cv.amount, 0)
      return sum / this.meterValues.length
    },

    averageAmountNormalized() {
      const sum = this.meterValues.reduce((pv, cv) => pv + cv.amount_normalized, 0)
      return sum / this.meterValues.length
    },

    averageGhgS1() {
      const sum = this.meterValues.reduce((pv, cv) => pv + cv.s1e, 0)
      return sum / this.meterValues.length
    },

    averageGhg() {
      const sum = this.meterValues.reduce((pv, cv) => pv + cv.s12e, 0)
      return sum / this.meterValues.length
    },

    averagePe() {
      const sum = this.meterValues.reduce((pv, cv) => pv + cv.pe, 0)
      return sum / this.meterValues.length
    },

    climateStationName() {
      return this.building?.location_info?.data?.climate_station?.name || '-'
    },

    climateStationAltitude() {
      return this.building?.location_info?.data?.climate_station?.altitude
        ? `${this.building.location_info.data.climate_station.altitude} m`
        : '-'
    },

    climateStationNormTemp() {
      return this.building?.location_info?.data?.climate_station?.norm_temperature
        ? `${this.building.location_info.data.climate_station.norm_temperature} °C`
        : '-'
    },
  },

  methods: {
    ...mapActions({
      addMeterValue: 'building/addMeterValue',
      updateMeterValue: 'building/updateMeterValue',
      deleteMeterValue: 'building/deleteMeterValue',
    }),

    getNextYear() {
      // Get the current year
      const currentYear = this.currentYear
      const years = this.meterYears

      // No values, return current year
      if (_.isEmpty(years)) return currentYear - 1

      const sortedYears = _.sortBy(years)
      // Filter out years that are not in range
      const filteredYears = _.filter(sortedYears, (year) => year <= currentYear && year >= this.minYear)

      // Check for the first missing year in the sequence
      for (let i = 0; i < filteredYears.length - 1; i++) {
        if (filteredYears[i + 1] - filteredYears[i] > 1) {
          return filteredYears[i] + 1
        }
      }

      // If no missing year is found and the edge year is not the current year, return the next year
      const edgeYear = _.last(filteredYears)

      if (edgeYear < currentYear) return edgeYear + 1

      // If filtered array is empty or the highest year is the current year, return empty result
      if (_.isEmpty(filteredYears) || _.last(filteredYears) === currentYear) return ''

      // If conditions fail
      return ''
    },

    onShowAdd() {
      this.editing = true
      // Get the next possible year value
      this.editYear = this.getNextYear()
      this.editAmount = ''
    },

    onCloseEdit() {
      this.editing = false
      this.editIndex = null
      this.editYear = null
      this.editAmount = null
    },

    onEdit(index) {
      const values = this.meterValues || []

      this.error = null
      this.editing = true
      this.editYear = values[index].year
      this.editAmount = values[index].amount
      this.editIndex = index
    },

    onDelete(index) {
      this.error = null
      setTimeout(async () => {
        if (confirm(this.$t('deleteConfirmation'))) {
          this.pending = true
          try {
            await this.deleteMeterValue({
              buildingId: this.meter.building_id,
              value: {
                id: this.meterValues[index].id,
                meter_id: this.meter.id,
              },
            })
          } catch (error) {
            this.error = error
          }
          this.pending = false
        }
      })
    },

    async onSave(index) {
      this.pending = true
      try {
        this.error = null
        await this.updateMeterValue({
          buildingId: this.meter.building_id,
          value: {
            meter_id: this.meter.id,
            id: this.meterValues[index].id,
            year: this.editYear,
            amount: this.editAmount,
          },
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
      this.onCloseEdit()
    },

    async onAdd() {
      this.pending = true
      try {
        this.error = null
        await this.addMeterValue({
          buildingId: this.meter.building_id,
          value: {
            meter_id: this.meter.id,
            year: this.editYear,
            amount: this.editAmount,
          },
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
      this.onCloseEdit()
    },
  },
}
</script>

<style lang="scss">
.c-meter-values-list {
  & .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .meter-values-table.detail-list > ul > li {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 120px;

    &.align-top {
      display: flex;
      align-items: baseline;

      & .c-list-button-wrapper {
        position: relative;
        top: 5px;
      }
    }
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
