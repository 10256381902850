<!--
Edit form for PvSectionSchema (used for measures and currently existing pv)
-->
<i18n>
{
  "de": {
    "ownUseRatio": "Eigenverbrauch",
    "addPvButton": "Photovoltaikanlage hinzufügen",
    "confirmDeletePv": "Wollen Sie diese Photovoltaikanlage wirklich löschen?",
    "pvCapacityTitle": "Installierte Leistung",
    "pvAngleTitle": "Neigung",
    "pvOrientationTitle": "Orientierung",
    "pvLossTitle": "Verluste",
    "noPvLabel": "Keine Photovoltaikanlage vorhanden",
    "pvProductionTitle": "Ertrag",
    "sourcePvGis": "Quelle: PVGIS",
    "sourceRoofTile": "Quelle: Sonnendach/BFE, PVGIS",
    "sourceFassadeTile": "Quelle: Sonnenfassade/BFE, PVGIS",
    "capacityInfoBoxTitle": "Installierte Peak-Leistung",
    "capacityInfoBox": "Norm-Leistung der Photovoltaikmodule",
    "angleInfoBoxTitle": "Neigung der Anlage",
    "angleInfoBox": "Winkel zur Horizontalen. 0° = horizontal",
    "orientationInfoBoxTitle": "Orientierung der Anlage (Azimuth)",
    "orientationInfoBox": "Himmelsrichtung in Grad, relativ zu Süden. 0° = Süden / 90° = Westen / -90° = Osten / 180° = Norden",
    "lossInfoBoxTitle": "Systemverluste",
    "lossInfoBox": "Verluste durch Kabel, Wechselrichter, Verschmutzung der Module, etc. Wenn dieses Feld leer gelassen wird, wird ein Standardwert von 14% angenommen.",
    "productionInfoBoxTitle": "Stromertrag",
    "productionInfoBox": "Erzeugte elektrische Energie nach Abzug von System-Verlusten. Wenn dieses Feld leer gelassen wird und die Orientierung, Neigung und Verluste der Anlage angegeben werden, wird der Ertrag über die PVGIS-Datenbank ermittelt.",
    "ownUseRatioInfoBoxTitle": "Eigenverbrauchsanteil",
    "ownUseRatioInfoBox": "Anteil Strom, der vor Ort verbraucht wird. Wenn dieses Feld leer gelassen wird, wird der Eigenverbrauchsanteil mittels monatlicher Strombilanzierung abgeschätzt."
  }
}
</i18n>

<template>
  <div class="c-edit-pv-sections">
    <DetailList v-if="model && model.length > 0" has-header class="pv-table">
      <template #header>
        <span class="align-right">
          <span>{{ $t('pvCapacityTitle') }}</span>
          <InfoBox :title="$t('capacityInfoBoxTitle')">{{ $t('capacityInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('pvAngleTitle') }}</span>
          <InfoBox :title="$t('angleInfoBoxTitle')">{{ $t('angleInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('pvOrientationTitle') }}</span>
          <InfoBox :title="$t('orientationInfoBoxTitle')">{{ $t('orientationInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('pvLossTitle') }}</span>
          <InfoBox :title="$t('lossInfoBoxTitle')">{{ $t('lossInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('pvProductionTitle') }}</span>
          <InfoBox :title="$t('productionInfoBoxTitle')">{{ $t('productionInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('ownUseRatio') }}</span>
          <InfoBox :title="$t('ownUseRatioInfoBoxTitle')">{{ $t('ownUseRatioInfoBox') }}</InfoBox>
        </span>
        <span v-if="edit"></span>
      </template>

      <li v-for="(p, sectionIndex) in model" :key="sectionIndex">
        <div class="align-right">
          <NumericInput
            v-model="p.capacity"
            :min="0.001"
            :edit="isEditing"
            :units="'kWp'"
            @input="onInput"
            @validation="onValidation($event, `capacity${sectionIndex}`)"
          />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <NumericInput
            v-else
            v-model="p.angle"
            :min="0"
            :max="89"
            :edit="isEditing"
            :units="'°'"
            @input="onInput"
            @validation="onValidation($event, `angle${sectionIndex}`)"
          />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <NumericInput
            v-else
            v-model="p.orientation"
            :min="-180"
            :max="180"
            :edit="isEditing"
            :units="'°'"
            @input="onInput"
            @validation="onValidation($event, `orientation${sectionIndex}`)"
          />
        </div>
        <div class="align-right">
          <span v-if="p.production_manual !== null">-</span>
          <NumericInput
            v-else
            v-model="p.loss"
            :min="0"
            :max="100"
            :edit="isEditing"
            :units="'%'"
            @input="onInput"
            @validation="onValidation($event, `loss${sectionIndex}`)"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-if="edit"
            v-model="p.production_manual"
            optional
            :min="0"
            :max="2000"
            :edit="isEditing"
            :units="'kWh/kWp'"
            @input="onInput"
            @validation="onValidation($event, `productionManual${sectionIndex}`)"
          />
          <div v-else-if="pvResults && pvResults[sectionIndex]">
            {{
              `${formatNumber(pvResults[sectionIndex].production.value, -1)} kWh (${formatNumber(
                pvResults[sectionIndex].production.value / p.capacity,
                0
              )} kWh/m²)`
            }}
            <div v-if="pvResults[sectionIndex].production.source === 'ROOF_TILE'" class="source-info">
              {{ $t('sourceRoofTile') }}
            </div>
            <div v-if="pvResults[sectionIndex].production.source === 'FASSADE_TILE'" class="source-info">
              {{ $t('sourceFassadeTile') }}
            </div>
            <div v-if="pvResults[sectionIndex].production.source === 'PVGIS'" class="source-info">
              {{ $t('sourcePvGis') }}
            </div>
          </div>
          <span v-else-if="!edit && p.production_manual">{{ p.production_manual }} kWh/m²</span>
        </div>
        <div class="align-right">
          <NumericInput
            v-model="p.ownuse_ratio_manual"
            optional
            :min="0"
            :max="100"
            :edit="isEditing"
            :units="'%'"
            @input="onInput"
            @validation="onValidation($event, `ownuseRatioManual${sectionIndex}`)"
          />
        </div>
        <ListButtonWrapper v-if="edit">
          <ListDeleteButton @click="onDeletePv(sectionIndex)" />
        </ListButtonWrapper>
      </li>
    </DetailList>

    <div v-else>{{ $t('noPvLabel') }}</div>

    <ButtonWrapper>
      <Button v-if="edit" icon="plus" :text="$t('addPvButton')" @click="onAddPv" />
    </ButtonWrapper>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    ListButtonWrapper,
    ListDeleteButton,
    ButtonWrapper,
    NumericInput,
    DetailList,
    InfoBox,
    Button,
  },

  props: {
    pvResults: {
      type: Array,
      required: false,
    },
  },

  watch: {
    model: {
      handler() {
        if (this.model === null) {
          this.setDefaults()
        }
        this.model.forEach((pv) => {
          // Emit validation error for null pv capacity (happens when first adding a pv)
          if (!this.validateCapacity(pv.capacity)) {
            this.$emit('validation', false)
          }
        })
      },
      deep: true, // This makes the watcher observe nested data
    },
  },

  created() {
    if (this.model === null) {
      this.setDefaults()
    }
  },

  methods: {
    setDefaults() {
      this.model = []
      this.onInput()
    },

    onAddPv() {
      const newPv = {
        capacity: null,
        loss: 14,
        orientation: 0,
        angle: 10,
        production_manual: null,
        ownuse_ratio_manual: null,
      }

      this.model.push(newPv)
      this.onInput()
    },

    validateCapacity(capacity) {
      return capacity !== null
    },

    onDeletePv(index) {
      if (confirm(this.$t('confirmDeletePv'))) {
        this.model.splice(index, 1)
        this.onInput()
      }
    },
  },
}
</script>

<style lang="scss">
.c-edit-pv-sections .detail-list.pv-table > ul > li {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 50px;

  & .source-info {
    color: var(--secondary-text-color);
    font-size: var(--font-xs);
    line-height: 16px;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
