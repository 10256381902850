<i18n>
{
  "de": {
    "source": {
      "DEFAULT": "Standardwert",
      "MEASURED": "Messdaten",
      "RENTAL_AREA": "Umrechnung HNF",
      "FLOOR_AREA": "Umrechnung BGF",
      "ENERGY_AREA": "Umrechnung EBF",
      "RENTABLE_AREA": "Umrechnung VMF",
      "GWR_EXTRAPOLATION": "Extrapolation GWR",
      "SONNENDACH_EXTRAPOLATION": "Extrapolation GIS-Gebäudedaten"
    },
    "rentalAreaTitle": "HNF",
    "floorAreaTitle": "BGF",
    "energyAreaTitle": "EBF",
    "rentableAreaTitle": "VMF",
    "usageTitle": "Nutzung",
    "addZoneButton": "Zone hinzufügen",
    "confirmDeleteZone": "Wollen Sie diese Zone wirklich löschen?",
    "remarksTitle": "Bemerkungen",
    "autoCalculationEnabled": "**",
    "autoCalculationCheckboxLabel": "Automatisch",
    "usageEstimatedLabel": "Nutzung unsicher",
    "areasEstimatedLabel": "Flächen unsicher",
    "featureEnabled": "Vorhanden",
    "hasDhwTitle": "Warmwasser",
    "dhwTitle": "Warmwasser",
    "tenantsTitle": "Mieterstrom",
    "generalTitle": "Allgemeinstrom",
    "total": "Total",
    "noZonesDefined": "Keine Zonen definiert",
    "floorAreaInfoBoxTitle": "Bruttogeschossfläche (BGF)",
    "floorAreaInfoBox": "Summe aller Grundflächen inklusive Konstruktionsflächen. Falls keine Hauptnutz- und Energiebezugsflächen bekannt sind, werden diese mit einem Umrechnungsfaktor über die Bruttogeschossfläche abgeschätzt.",
    "rentalAreaInfoBoxTitle": "Hauptnutzflächen (HNF)",
    "rentalAreaInfoBox": "Hauptnutzfläche nach SIA 416. Falls die Energiebezugsfläche nicht bekannt ist, wird diese mit einem Umrechnungsfaktor über die Hauptnutzfläche abgeschätzt.",
    "energyAreaInfoBoxTitle": "Energiebezugsfläche (EBF)",
    "energyAreaInfoBox": "Die EBF dient als Basis für alle spezifischen Energie- und CO₂-Kennzahlen. Definition: Summe aller ober- und unterirdischen Geschossflächen, die innerhalb der thermischen Gebäudehülle liegen und für die ein Beheizen oder Klimatisieren notwendig ist (siehe SIA 380/1).",
    "rentableAreaInfoBoxTitle": "Vermietbare Fläche (VMF)",
    "rentableAreaInfoBox": "Falls die Energiebezugsfläche nicht bekannt ist, wird diese mit einem nutzungsspezifischen Umrechnungsfaktor über die vermietbare Fläche abgeschätzt.",
    "dhwInfoBoxTitle": "Spezifischer Nutzenergiebedarf für Warmwasser (Q_W)",
    "dhwInfoBox": "Wenn dieses Feld leer gelassen wird, werden Standardwerte für diese Nutzung verwendet (siehe Standardwerte SIA 2024:2021 Anhang F Tabelle 20).",
    "tenantsInfoBoxTitle": "Spezifischer Elektrizitätsbedarf Mieter",
    "tenantsInfoBox": "Wenn dieses Feld leer gelassen wird, werden entweder Standardwerte für diese Nutzung (siehe Standardwerte SIA 2024:2021 Anhang F Tabelle 20) oder gewichtete Durchschnittswerte aus den Verbrauchsdaten verwendet.",
    "generalInfoBoxTitle": "Spezifischer Elektrizitätsbedarf allgemeine Gebäudetechnik",
    "generalInfoBox": "Wenn dieses Feld leer gelassen wird, werden entweder Standardwerte für diese Nutzung (siehe Standardwerte SIA 2024:2021 Anhang F Tabelle 20) oder gewichtete Durchschnittswerte aus den Verbrauchsdaten verwendet.",
    "invalidZones": "Die Zusammensetzung der Zonen ist ungültig.",
    "invalidZonesExplanation": "Die Flächen von Zonen müssen so gewählt sein, dass mindestens eine Zone eine (berechnete) EBF ungleich 0 hat. Dabei muss darauf geachtet werden, dass Ableitungen von BGF und HNF gemacht werden, wenn also BGF 0 ist und EBF nicht gesetzt ist, dann ist die berechnete EBF 0, was nicht erlaubt ist. Es ist hingegen erlaubt alle Flächen ungesetzt zu lassen."
  }
}
</i18n>

<template>
  <div class="edit-zones-table">
    <DetailList v-if="model.length > 0" has-header has-footer class="zone-table">
      <template #header>
        <span>{{ $t('usageTitle') }}</span>
        <span class="align-right">
          <span>{{ $t('floorAreaTitle') }}</span>
          <InfoBox :title="$t('floorAreaInfoBoxTitle')">{{ $t('floorAreaInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('rentalAreaTitle') }}</span>
          <InfoBox :title="$t('rentalAreaInfoBoxTitle')">{{ $t('rentalAreaInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('rentableAreaTitle') }}</span>
          <InfoBox :title="$t('rentableAreaInfoBoxTitle')">{{ $t('rentableAreaInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('energyAreaTitle') }}</span>
          <InfoBox :title="$t('energyAreaInfoBoxTitle')">{{ $t('energyAreaInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('dhwTitle') }}</span>
          <InfoBox :title="$t('dhwInfoBoxTitle')">{{ $t('dhwInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('tenantsTitle') }}</span>
          <InfoBox :title="$t('tenantsInfoBoxTitle')">{{ $t('tenantsInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('generalTitle') }}</span>
          <InfoBox :title="$t('generalInfoBoxTitle')">{{ $t('generalInfoBox') }}</InfoBox>
        </span>
        <span class="align-right"></span>
      </template>

      <li v-for="(zone, zoneIdx) in model" :key="zoneIdx">
        <template>
          <template v-if="isEditing">
            <ZoneUsagePicker v-model="zone.usage" @input="onInput" />
          </template>
          <p v-else>
            {{ $t(`_usageTypes.${zone.usage}`) }}
          </p>
        </template>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.floor_area.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'m²'"
            @input="onInput"
            @validation="onValidation($event, `floorArea${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx] && zones[zoneIdx].floor_area.deduced">
            {{ zones[zoneIdx].floor_area.deduced }} m²
            <div v-if="allowedSource(zones[zoneIdx].floor_area.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].floor_area.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.floor_area >= 0">{{ formatNumber(zone.floor_area, 0) }} m²</span>
          <span v-else-if="!isEditing">-</span>
        </div>

        <div class="align-right">
          <NumericInput
            v-model="zone.rental_area"
            :min="0"
            optional
            :edit="isEditing"
            :units="'m²'"
            @input="onInput"
            @validation="onValidation($event, `rentalArea${zoneIdx}`)"
          />
        </div>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.rentable_area.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'m²'"
            @input="onInput"
            @validation="onValidation($event, `rentableArea${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx] && zones[zoneIdx].rentable_area.deduced">
            {{ zones[zoneIdx].rentable_area.deduced }} m²
            <div v-if="allowedSource(zones[zoneIdx].rentable_area.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].rentable_area.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.rentable_area >= 0">{{ formatNumber(zone.rentable_area, 0) }} m²</span>
          <span v-else-if="!isEditing">-</span>
        </div>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.energy_area.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'m²'"
            @input="onInput"
            @validation="onValidation($event, `energyArea${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx] && zones[zoneIdx].energy_area.deduced">
            {{ zones[zoneIdx].energy_area.deduced }} m²
            <div v-if="allowedSource(zones[zoneIdx].energy_area.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].energy_area.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.energy_area >= 0">{{ formatNumber(zone.energy_area, 0) }} m²</span>
          <span v-else-if="!isEditing">-</span>
        </div>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.dhw_demand.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'kWh/m²'"
            @input="onInput"
            @validation="onValidation($event, `dhwDemand${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx]">
            {{ zones[zoneIdx].dhw_demand.deduced }} kWh/m²
            <div v-if="allowedSource(zones[zoneIdx].dhw_demand.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].dhw_demand.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.dhw_demand.manual">{{ zone.dhw_demand.manual }} kWh/m²</span>
        </div>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.el_tenants.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'kWh/m²'"
            @input="onInput"
            @validation="onValidation($event, `elTenants${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx]">
            {{ zones[zoneIdx].el_tenants.deduced }} kWh/m²
            <div v-if="allowedSource(zones[zoneIdx].el_tenants.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].el_tenants.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.el_tenants">{{ zone.el_tenants }}</span>
        </div>

        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="zone.el_general.manual"
            :min="0"
            optional
            :edit="isEditing"
            :units="'kWh/m²'"
            @input="onInput"
            @validation="onValidation($event, `elGeneral${zoneIdx}`)"
          />
          <div v-if="!isEditing && zones && zones[zoneIdx]">
            {{ zones[zoneIdx].el_general.deduced }} kWh/m²
            <div v-if="allowedSource(zones[zoneIdx].el_general.source)" class="source-info">
              {{ $t(`source.${zones[zoneIdx].el_general.source}`) }}
            </div>
          </div>
          <span v-else-if="!isEditing && zone.el_general">{{ zone.el_general }} kWh/m²</span>
        </div>

        <ListButtonWrapper>
          <ListDeleteButton v-if="isEditing" @click="onDeleteZone(zoneIdx)" />
        </ListButtonWrapper>
      </li>

      <template #footer>
        <span>{{ $t('total') }}</span>
        <span v-if="totalFloorArea !== undefined" class="align-right">{{ formatNumber(totalFloorArea, 0) }} m²</span>
        <span v-else class="align-right">-</span>
        <span v-if="totalRentalArea !== undefined" class="align-right">{{ formatNumber(totalRentalArea, 0) }} m²</span>
        <span v-else class="align-right">-</span>
        <span v-if="totalRentableArea !== undefined" class="align-right"
          >{{ formatNumber(totalRentableArea, 0) }} m²</span
        >
        <span v-else class="align-right">-</span>
        <span v-if="totalEnergyArea !== undefined" class="align-right">
          <span>{{ formatNumber(totalEnergyArea, 0) }} m²</span>
        </span>
        <span v-else class="align-right">-</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </template>
    </DetailList>
    <div v-else>{{ $t('noZonesDefined') }}</div>

    <div v-if="!validZones" class="invalid">
      <span>{{ $t('invalidZones') }}</span>
      <InfoBoxV2>{{ $t('invalidZonesExplanation') }}</InfoBoxV2>
    </div>

    <ButtonWrapper>
      <Button v-if="isEditing" icon="plus" :text="$t('addZoneButton')" @click="onAddZone" />
    </ButtonWrapper>
  </div>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import ZoneUsagePicker from '@/components/building/edit/ZoneUsagePicker.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import InfoBoxV2 from '@/components/shared/InfoBoxV2.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    NumericInput,
    InfoBox,
    InfoBoxV2,
    ZoneUsagePicker,
    DetailList,
    Button,
  },

  props: {
    zones: {
      type: Array,
      required: true,
    },
  },

  computed: {
    totalEnergyArea() {
      let sum
      if (this.edit) {
        sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.energy_area.manual), 0)
      } else if (this.zones) {
        sum = this.zones.reduce((pv, cv) => pv + parseFloat(cv.energy_area.deduced), 0)
      } else {
        return undefined
      }
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    totalRentableArea() {
      let sum
      if (this.edit) {
        sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.rentable_area.manual), 0)
      } else if (this.zones) {
        sum = this.zones.reduce((pv, cv) => pv + parseFloat(cv.rentable_area.deduced), 0)
      } else {
        return undefined
      }
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    totalRentalArea() {
      const sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.rental_area), 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    totalFloorArea() {
      let sum
      if (this.edit) {
        sum = this.model.reduce((pv, cv) => pv + parseFloat(cv.floor_area.manual), 0)
      } else if (this.zones) {
        sum = this.zones.reduce((pv, cv) => pv + parseFloat(cv.floor_area.deduced), 0)
      } else {
        return undefined
      }
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    // Overridden
    isValid() {
      return this.noInvalidFields && this.validZones
    },

    // Tightly coupled with web-backend: util.validation.__valid_zone
    validZones() {
      if (!this.model) {
        return true
      }
      return this.model.some((zone) => {
        if (zone.energy_area.manual !== null) {
          return zone.energy_area.manual > 0
        }
        if (zone.rentable_area.manual !== null) {
          return zone.rentable_area.manual > 0
        }
        if (zone.rental_area !== null) {
          return zone.rental_area > 0
        }
        if (zone.floor_area.manual !== null) {
          return zone.floor_area.manual > 0
        }
        return true
      })
    },
  },

  watch: {
    isEditing() {
      this.onInput()
    },
  },

  mounted() {
    this.onInput()
  },

  methods: {
    onAddZone() {
      this.model.push({
        usage: 'UNKNOWN',
        energy_area: {
          manual: null,
          deduced: null,
          source: null,
        },
        rentable_area: {
          manual: null,
          deduced: null,
          source: null,
        },
        rental_area: null,
        floor_area: {
          manual: null,
          deduced: null,
          source: null,
        },
        dhw_demand: {
          manual: null,
          deduced: null,
          source: null,
        },
        el_tenants: {
          manual: null,
          deduced: null,
          source: null,
        },
        el_general: {
          manual: null,
          deduced: null,
          source: null,
        },
      })
      this.onInput()
    },

    onDeleteZone(index) {
      if (confirm(this.$t('confirmDeleteZone'))) {
        this.model.splice(index, 1)
        this.onInput()
      }
    },

    allowedSource(source) {
      return [
        //
        'DEFAULT',
        'MEASURED',
        'RENTAL_AREA',
        'FLOOR_AREA',
        'ENERGY_AREA',
        'RENTABLE_AREA',
        'GWR_EXTRAPOLATION',
        'SONNENDACH_EXTRAPOLATION',
      ].includes(source)
    },
  },
}
</script>

<style scoped>
.invalid {
  padding: 5px;
  color: var(--error-color);
}
</style>

<style lang="scss">
.edit-zones-table {
  & .hint {
    font-size: var(--font-xs);
    padding: var(--box-padding);
  }

  & .source-info {
    color: var(--secondary-text-color);
    font-size: var(--font-xs);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
