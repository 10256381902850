<i18n>
{
  "de": {
    "yLabels": {
      "ee": "Endenergie (kWh/m²)",
      "s12e": "Scope 1-2 Emissionen (kg CO₂e/m²)",
      "s123e": "Scope 1-3 Emissionen (kg CO₂e/m²)",
      "per": "Primärenergie (erneuerbar) (kWh/m²)"
    },
    "units": {
      "ee": "kWh/m²",
      "s12e": "kg CO₂e/m²",
      "s123e": "kg CO₂e/m²",
      "per": "kWh/m²"
    },
    "modelLabel": "Modell"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="kpi-by-carrier-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByCarrier.faulty" :portfolio="portfolio" />
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectionsByCarrier: null,
      legendItemsHidden: [],
    }
  },
  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yLabel() {
      if (this.getIndicatorName === this.kpiType) {
        return this.$t(`yLabels.${this.kpiType}`)
      }
      return `${this.getIndicatorName} (${this.specificUnit})`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    specificUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getSpecificTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  title: this.$t('modelLabel'),
                  precision: 1,
                  unit: this.specificUnit,
                }),
              label: (item) => this.getTooltipLabelWithPercentage(item, { unit: this.specificUnit }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
            labels: {
              padding: 15,
              generateLabels: this.generateLegendLabels,
            },
            onClick: (event, legendItem) => {
              this.onLegendItemClick(event.chart, event, legendItem)
            },
          },
        },
      }
    },

    chartData() {
      const sortOrder = [
        'ELECTRICITY_GRID',
        'ELECTRICITY_PV',
        'WOOD_CHIPS',
        'PELLET',
        'SOLAR_HEAT',
        'DISTRICT_HEAT',
        'LOCAL_HEAT',
        'HEATING_OIL',
        'NATURAL_GAS',
      ]
      let datasets = []
      for (const carrierName in this.projectionsByCarrier.projections) {
        const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.name === carrierName)
        if (Object.prototype.hasOwnProperty.call(this.projectionsByCarrier.projections, carrierName) && energyCarrier) {
          let backgroundColor = colorPalettes.carrierColors[carrierName]
          if (backgroundColor === undefined) {
            backgroundColor = '#' + Math.floor(Math.random() * 16777215).toString(16) // https://css-tricks.com/snippets/javascript/random-hex-color/
          }
          datasets.push({
            carrierCode: carrierName,
            label: energyCarrier.default ? this.$t(`_energyCarriers.${energyCarrier.name}`) : energyCarrier.name,
            backgroundColor: backgroundColor,
            data: this.projectionsByCarrier.projections[carrierName].value.map(
              (v, i) => v / this.projectionsByCarrier.projections[carrierName].area[i]
            ),
            stack: 'Calculated',
          })
        }
      }
      datasets.sort((a, b) => sortOrder.indexOf(a.carrierCode) - sortOrder.indexOf(b.carrierCode))
      return {
        labels: this.projectionsByCarrier.years,
        datasets: datasets,
      }
    },
  },
  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },
  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.projectionsByCarrier = await compassApi.call(
        `/projections/${this.portfolio.id}/${this.kpiType}/by_carrier/${this.scenario.id}?query=${query}`
      )
    },

    generateLegendLabels() {
      const carrierCodes = this.chartData.datasets.map((d) => d.carrierCode)
      const carrierCodesWithoutDuplicates = [...new Set(carrierCodes)]
      return carrierCodesWithoutDuplicates
        .filter((c) => c !== 'ELECTRICITY')
        .map((carrierName) => {
          const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.name === carrierName)
          let fillStyle = colorPalettes.carrierColors[carrierName]
          if (fillStyle === undefined) {
            fillStyle = this.chartData.datasets.filter((c) => c.carrierCode === carrierName)[0].backgroundColor
          }
          return {
            carrierCode: carrierName,
            text: energyCarrier.default ? this.$t(`_energyCarriers.${energyCarrier.name}`) : energyCarrier.name,
            fillStyle: fillStyle,
            hidden: this.legendItemsHidden.includes(carrierName),
          }
        })
    },

    onLegendItemClick(chart, e, legendItem) {
      if (this.legendItemsHidden.includes(legendItem.carrierCode)) {
        this.legendItemsHidden = this.legendItemsHidden.filter((l) => l !== legendItem.carrierCode)
      } else {
        this.legendItemsHidden.push(legendItem.carrierCode)
      }
      for (let dataIdx = 0; dataIdx < chart.data.datasets.length; dataIdx++) {
        if (chart.data.datasets[dataIdx].carrierCode === legendItem.carrierCode) {
          let meta = chart.getDatasetMeta(dataIdx)
          meta.hidden = this.legendItemsHidden.includes(legendItem.carrierCode)
        }
      }
      chart.update()
    },
  },
}
</script>

<style>
.kpi-by-carrier-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
/* .kpi-by-carrier-chart > div {
  position: absolute;
  top: var(--spacing-s); bottom: var(--spacing-s); left: var(--spacing-s); right: var(--spacing-s);
} */
</style>
