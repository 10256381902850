<i18n>
{
  "de": {
    "loading": "Daten werden geladen..."
  }
}
</i18n>

<template>
  <div class="l-split-layout">
    <div sticky-container class="main-panel">
      <LayoutHeader :portfolio="portfolio" :portfolios="portfolios" />

      <div v-if="ready" class="page-header">
        <slot name="header"></slot>
      </div>

      <div class="compass-contents">
        <div v-if="!ready">
          {{ $t('loading') }}
        </div>
        <template v-if="ready">
          <slot></slot>
        </template>
      </div>
    </div>

    <div class="side-panel">
      <template v-if="ready">
        <slot name="side-panel"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import LayoutHeader from '@/components/shared/LayoutHeader.vue'

export default {
  components: {
    LayoutHeader,
  },

  props: {
    portfolio: {
      type: Object,
    },
    ready: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState('portfolio', ['portfolios']),
  },
}
</script>

<style lang="scss" scoped>
.l-split-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100svh;

  & > * {
    height: 100%;
    overflow-y: auto;
    flex: none;
    width: calc(100% - 33svw);
  }

  & .main-panel {
    flex: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    & .compass-contents {
      flex: 1;
      padding: var(--spacing-m); // 16px all around
      background-color: #fff;

      @media screen and (max-width: 1280px) {
        padding: 0 var(--spacing-m) var(--spacing-m);
      }
    }
  }

  & .side-panel {
    flex: none;
    width: 33svw;
    max-width: 720px;
    height: 100%;
    border-left: 1px solid #ddd;

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
}
</style>
