<template>
  <div :class="`c-kpi-box ${className}`">
    <div class="label">{{ label }}</div>
    <div class="value">
      {{ value }}
      <span class="unit">{{ valueUnit }}</span>
    </div>
    <div v-if="relativeValue" class="relative-value">
      {{ relativeValue }}
      <span v-if="relativeValueUnit">{{ relativeValueUnit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String || Number,
      required: true,
    },
    valueUnit: {
      type: String,
      required: true,
    },
    relativeValue: {
      type: String,
    },
    relativeValueUnit: {
      type: String,
    },
    className: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.c-kpi-box {
  flex: 1;

  & .label {
    font-size: 14px;
    line-height: 24px;
  }

  & .value {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    height: 26px;

    & span.unit {
      font-size: var(--font-s);
    }
  }

  & .relative-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #888;
  }
}
</style>
