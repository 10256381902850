<i18n>
{
  "de": {
    "initialStateTitle": "Initialer Zustand"
  }
}
</i18n>

<template>
  <div class="c-building-initial-state">
    <!-- Timeline -->
    <TimelineElement type="INITIAL" :is-last-measure="isLastMeasure" />

    <!-- Initial State -->
    <div class="initial-state-wrapper">
      <Card>
        <CardHeader :title="$t('initialStateTitle')">
          <template #prefix>
            <div>{{ building?.initial_state?.result?.envelope?.building_year?.value }}</div>
          </template>
        </CardHeader>

        <!-- Building details -->
        <CardContent>
          <div class="content-wrapper">
            <!-- Summary Rows -->
            <List divider class="building-details">
              <EnvelopeSummaryRow :building="building" :portfolio="portfolio" />
              <ZonesSummaryRow :building="building" />
              <HeatingSummaryRow :building="building" :portfolio="portfolio" />
              <ElectricitySummaryRow :portfolio="portfolio" :building="building" />
              <PhotovoltaicSummaryRow :building="building" />
              <RenovationStandardsSummaryRow :building="building" :portfolio="portfolio" />
            </List>

            <!-- KPI Report card -->
            <KpiReportCard
              :s12e-value="building.initial_state.result.ghg / energyArea"
              :energy-efficiency-value="building.initial_state.result.pe / energyArea"
              :heating-efficiency-value="building.initial_state.result.rh_demand / energyArea"
              :energy-efficiency-ratio="building.initial_state.result.pe_ratio"
              :heating-efficiency-ratio="building.initial_state.result.rh_ratio"
            />
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import EnvelopeSummaryRow from '@/components/building/overview/EnvelopeSummaryRow.vue'
import ZonesSummaryRow from '@/components/building/overview/ZonesSummaryRow.vue'
import HeatingSummaryRow from '@/components/building/overview/HeatingSummaryRow.vue'
import ElectricitySummaryRow from '@/components/building/overview/ElectricitySummaryRow.vue'
import PhotovoltaicSummaryRow from '@/components/building/overview/PhotovoltaicSummaryRow.vue'
import RenovationStandardsSummaryRow from '@/components/building/overview/RenovationStandardsSummaryRow.vue'
import KpiReportCard from '@/components/building/measures/KpiReportCard.vue'
import TimelineElement from '@/components/building/measures/TimelineElement.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    EnvelopeSummaryRow,
    ZonesSummaryRow,
    HeatingSummaryRow,
    ElectricitySummaryRow,
    PhotovoltaicSummaryRow,
    RenovationStandardsSummaryRow,
    KpiReportCard,
    TimelineElement,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    isLastMeasure: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    energyArea() {
      return this.building.initial_state.zones.zones.reduce((acc, zone) => acc + zone.energy_area.deduced, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-building-initial-state {
  display: flex;
  flex-direction: row;
  width: 100%;

  .initial-state-wrapper {
    width: 100%;
    margin: 20px 20px 0 0;

    & .content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      & .building-details {
        flex: 1;
        border-right: var(--box-border);

        @media screen and (max-width: 960px) {
          border-right: none;
          border-bottom: var(--box-border);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.c-building-initial-state {
  .cui-card {
    overflow: hidden;
  }
}
</style>
