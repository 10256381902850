<i18n>
  {
    "de": {
      "exportCsvButton": "Daten (CSV)",
      "exportPngButton": "Bild (PNG)",
      "showFullLegend": "Gesamte Legende anzeigen...",
      "minimize": "Minimieren",
      "maximize": "Maximieren"
    }
  }
</i18n>

<template>
  <div class="c-exportable-doughnut-chart">
    <Doughnut
      ref="chart"
      :class="`chart-container${expanded ? ' expanded' : ''}`"
      :chart-data="chartData"
      :chart-options="chartOptions"
    />
    <div
      v-if="exportable"
      ref="exportWrapper"
      class="export-wrapper"
      :style="{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }"
    >
      <canvas ref="exportCanvas" />
    </div>
    <div v-if="expandable && canExpand && !expanded" class="expand-info" @click="switchChartView">
      {{ $t('showFullLegend') }}
    </div>
    <div v-if="exportable || expandable" class="action-buttons">
      <Button
        v-if="exportable"
        class-name="button--ghost"
        icon="download"
        :text="$t('exportCsvButton')"
        @click="exportAsCsv"
      />
      <Button
        v-if="exportable"
        class-name="button--ghost"
        icon="download"
        :text="$t('exportPngButton')"
        @click="exportAsPng"
      />
      <Button
        v-if="expandable && canExpand"
        class-name="button--ghost"
        :icon="expanded ? 'minimize-2' : 'maximize-2'"
        :tooltip="expanded ? $t('minimize') : $t('maximize')"
        @click="switchChartView"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { Doughnut } from 'vue-chartjs/legacy'

import exportService from '@/services/export-service.js'

import Button from '@/components/cui/inputs/Button.vue'

export default {
  components: {
    Doughnut,
    Button,
  },

  props: {
    chartData: {
      type: Object,
    },
    options: {
      type: Object,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: Object,
      default: () => ({
        width: 480,
        height: 360,
      }),
    },
  },

  data() {
    return {
      chartInstance: null,
      expanded: false,
      canExpand: false,
    }
  },

  computed: {
    chartOptions() {
      let options = _.cloneDeep(this.options)
      if (!options?.plugins?.annotation) _.set(options, 'plugins.annotation.annotations', {})
      options.animation = {
        ...(this.options.animation || {}),
        onComplete: () => {
          this.onChartRender(this.$refs.chart)
        },
      }
      return options
    },
  },

  mounted() {
    this.chartInstance = this.$refs.chart
  },

  methods: {
    exportAsCsv() {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    },

    exportAsPng() {
      // Render the export wrapper
      this.$refs.exportWrapper.style.display = 'block'

      const { chart } = exportService.chartToExportCanvas(
        this.chartInstance.getCurrentChart(),
        this.$refs.exportCanvas,
        this.chartData,
        this.options,
        this.imageSize
      )

      if (chart) {
        exportService.savePng(chart, 'chart.png')
        // Hide the export wrapper after exporting
        this.$refs.exportWrapper.style.display = 'none'
        // Remove export chart instance
        chart.destroy()
      }
    },

    switchChartView() {
      this.expanded = !this.expanded
    },

    onChartRender(chartRef) {
      const chart = chartRef.getCurrentChart()

      if (chart?.legend?.legendHitBoxes?.length > 0 && !this.canExpand) {
        this.canExpand = _.max(chart.legend.legendHitBoxes.map((box) => box.top)) > chart.legend.bottom
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-exportable-doughnut-chart {
  position: relative;
  width: 100%;

  & .action-buttons {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 10px;
  }

  & .chart-container {
    position: relative;
    transition: width 0.5s ease, height 0.5s ease;
  }

  & .expand-info {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-top: 2px;
    color: rgb(106, 106, 106);
    cursor: pointer;
  }

  & .chart-container.expanded {
    height: 30svw !important;
  }

  & .export-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
  }
}
</style>
