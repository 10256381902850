<i18n>
  {
    "de": {
      "title": "Energie",
      "endEnergyDemand": "Endenergiebedarf",
      "total": "Total",
      "intensity": "Intensität",
      "indicator": {
        "rh_demand": "Nutzenergie Raumwärme",
        "dhw_demand": "Nutzenergie Warmwasser",
        "el_demand": "Strombedarf",
        "el_general": "Allgemeinstrom",
        "el_tenants": "Mieterstrom"
      }
    }
  }
  </i18n>

<template>
  <KpiOverviewCard
    class="c-energy-kpi-overview"
    :title="$t('title')"
    titleIcon="cards/energy"
    @details-click="onModalOpen()"
  >
    <template #values>
      <KpiBox
        :label="$t('endEnergyDemand')"
        :value="energyData[0].total"
        valueUnit="MWh"
        :relativeValue="energyData[0].intensity"
        relativeValueUnit="kWh/m²"
      />
    </template>
    <template #chart>
      <KpiDoughnutChart
        kpi-type="ee"
        :group-by-type="'usage'"
        :portfolio="portfolio"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
    </template>
    <template #table>
      <DetailList has-header>
        <!-- Headers -->
        <template #header class="row">
          <div class="name" />
          <HeaderEntry :title="$t('total')" unit="MWh" class="align-right" />
          <HeaderEntry :title="$t('intensity')" unit="kWh/m²" class="align-right" />
        </template>

        <!-- Rows, data -->
        <template>
          <li v-for="item in tableData" :key="item.indicator" class="row">
            <div class="name">
              <span v-if="['el_general', 'el_tenants'].includes(item.indicator)" class="tab" />
              <span class="indicator">
                {{ $t(`indicator.${item.indicator}`) }}
              </span>
            </div>
            <div class="align-right bold">
              {{ item.total }}
            </div>
            <div class="align-right gray">
              {{ item.intensity }}
            </div>
          </li>
        </template>
      </DetailList>
    </template>
    <template #modal>
      <EnergyKpiModal
        v-if="modalOpen"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
        :kpi-data="energyData"
        @close="onModalClose()"
      />
    </template>
  </KpiOverviewCard>
</template>

<script>
import _ from 'lodash'

import KpiOverviewCard from '@/components/shared/kpi/KpiOverviewCard.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import KpiDoughnutChart from '@/components/portfolio/kpi/charts/KpiDoughnutChart.vue'
import EnergyKpiModal from '@/components/building/kpi/EnergyKpiModal.vue'

export default {
  components: {
    KpiBox,
    KpiDoughnutChart,
    KpiOverviewCard,
    DetailList,
    HeaderEntry,
    EnergyKpiModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
        },
      }
    },

    energyData() {
      return this.getValues(this.building, 'initial_state.result.computations.totals', ['ee', 'pe'])
    },

    tableData() {
      return [
        ...this.getValues(this.building, 'initial_state.result', ['rh_demand', 'dhw_demand']),
        ...this.getValues(this.chartData[0], null, ['el_demand', 'el_general', 'el_tenants']),
      ]
    },
  },

  methods: {
    getValues(sourceObject, path, indicators) {
      return _.map(indicators, (indicator) => {
        const value = path ? _.get(sourceObject, `${path}.${indicator}`) : sourceObject?.[indicator]
        const hasValue = value !== undefined && value !== null
        const totalValue = hasValue ? this.formatNumber(value / 1000, 1) : null
        const intensityValue = totalValue !== null ? this.formatNumber(value / this.building.kpi.energy_area, 1) : null

        return {
          indicator,
          total: totalValue || '-',
          intensity: intensityValue || '-',
        }
      })
    },

    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-energy-kpi-overview {
  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .name {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .indicator {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .tab {
      width: 8px;
    }
  }

  & .bold {
    font-weight: bold;
  }

  & .gray {
    color: #888;
  }
}
</style>

<style lang="scss">
.c-energy-kpi-overview {
  & .detail-list > ul > li > * {
    flex: 1 !important;
    width: 0 !important;
  }

  & .detail-list > ul > li > *.name {
    flex: 2 !important;
  }
}
</style>
