<i18n>
{
  "de": {
    "createMeasureTitle": "Massnahme erstellen",
    "scenarioMeasureTitle": "Szenario-Massnahme",
    "plannedMeasureTitle": "Geplante Massnahme",
    "completedMeasureTitle": "abgeschlossene Massnahme",
    "editScenarioMeasureTitle": "überarbeiten",
    "editPlannedMeasureTitle": "bearbeiten"
  }
}
</i18n>
<template>
  <Modal wide @close="$emit('close', $event)">
    <template #header>
      <h1>
        <template v-if="!measure">
          {{ $t('createMeasureTitle') }}
        </template>
        <template v-else-if="measure && measure.phase === 'COMPLETED'">
          {{ $t('completedMeasureTitle') }}
        </template>
        <template v-else-if="measure && measure.phase === 'SCENARIO'">
          {{ $t('scenarioMeasureTitle') }}
        </template>
        <template v-else-if="measure && ['SCENARIO_EDITED', 'PLANNED'].includes(measure.phase)">
          {{ $t('plannedMeasureTitle') }}
        </template>
        <template v-if="measure && measure.phase === 'SCENARIO' && isEditing">
          {{ $t('editScenarioMeasureTitle') }}
        </template>
        <template v-if="measure && ['SCENARIO_EDITED', 'PLANNED'].includes(measure.phase) && isEditing">
          {{ $t('editPlannedMeasureTitle') }}
        </template>
      </h1>
    </template>

    <EditMeasure
      :measure="measure"
      :building="building"
      :last-kpi="lastKpi"
      :portfolio="portfolio"
      :selected-scenario="selectedScenario"
      @cancel="onCancel"
      @created="onCreated"
      @edit="isEditing = $event"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EditMeasure from '@/components/building/measures/edit/EditMeasure.vue'

export default {
  components: {
    Modal,
    EditMeasure,
  },

  props: {
    selectedScenario: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
    },
    portfolio: {
      type: Object,
    },
    measure: {
      // edit existing measure
      type: Object,
    },
    lastKpi: {
      type: Object,
    },
  },

  data() {
    return {
      isEditing: false,
    }
  },

  methods: {
    onCancel() {
      if (!this.measure) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },

    onCreated() {
      this.$emit('close') // Close modal after creating new measure
    },
  },
}
</script>
