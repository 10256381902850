<i18n>
{
  "de": {
    "energy": "Energie",
    "endEnergy": "Endenergie",
    "primaryEnergy": "Primärenergie",
    "energyDemand": {
      "ee": "Endenergiebedarf",
      "pe": "Primärenergiebedarf"
    },
    "energyUsage": "Verwendungszweck",
    "energyCarrier": "Energieträger",
    "measurementData": "Messdaten"
  }
}
</i18n>

<template>
  <Modal class="c-energy-kpi-modal" :width="800" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('energy') }}</h2>
    </template>

    <nav class="toggle-buttons kpi-type-switch">
      <button type="button" :class="{ selected: kpiType === 'ee' }" @click="setKpiType('ee')">
        <span>{{ $t('endEnergy') }}</span>
      </button>
      <button type="button" :class="{ selected: kpiType === 'pe' }" @click="setKpiType('pe')">
        <span>{{ $t('primaryEnergy') }}</span>
      </button>
    </nav>

    <div class="content">
      <div class="cards-row">
        <div class="kpi-values card">
          <h3>{{ $t(`energyDemand.${kpiType}`) }}</h3>
          <KpiBox
            :value="kpiData.find((kpi) => kpi.indicator === kpiType).total"
            valueUnit="MWh"
            :relativeValue="kpiData.find((kpi) => kpi.indicator === kpiType).intensity"
            relativeValueUnit="kWh/m²"
          />
        </div>
        <div class="energy-demand-chart card">
          <h3>{{ $t('energyUsage') }}</h3>
          <KpiDoughnutChart
            :kpi-type="kpiType"
            :group-by-type="'usage'"
            :portfolio="portfolio"
            :chart-data="chartData"
            :chart-options="chartOptions"
            exportable
          />
        </div>
      </div>

      <!-- Energy Carriers -->
      <div class="energy-carrier-chart card">
        <h3>{{ $t('energyCarrier') }}</h3>
        <KpiDoughnutChart
          :kpi-type="kpiType"
          :group-by-type="'carrier'"
          :portfolio="portfolio"
          :chart-data="chartData"
          :chart-options="chartOptions"
          exportable
        />
      </div>

      <!-- Meters -->
      <div class="measurement-data-chart card">
        <h3>{{ $t('measurementData') }}</h3>
        <MeasuredKpiChart :kpi-type="kpiType" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </div>

      <br />

      <!-- Projections 1 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['usage', 'carrier']"
          default-projection="usage"
          :kpi-type="kpiType"
        />
      </div>

      <br />

      <!-- Projections 2 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['usage', 'carrier']"
          default-projection="carrier"
          :kpi-type="kpiType"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'

import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import KpiDoughnutChart from '@/components/portfolio/kpi/charts/KpiDoughnutChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import ProjectionChart from '@/components/building/kpi/ProjectionChart.vue'

export default {
  components: {
    Modal,
    KpiBox,
    KpiDoughnutChart,
    MeasuredKpiChart,
    ProjectionChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    kpiData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      kpiType: 'ee',
    }
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          legend: {
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
        },
      }
    },
  },

  methods: {
    setKpiType(kpiType) {
      this.kpiType = kpiType
    },
  },
}
</script>

<style lang="scss" scoped>
.c-energy-kpi-modal {
  display: flex;
  flex-direction: column;

  & .kpi-type-switch {
    width: 100%;
  }

  & .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    & .cards-row {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    & .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .kpi-values {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      flex: 1;
    }

    & .energy-demand-chart,
    & .energy-carrier-chart,
    & .measurement-data-chart {
      flex: 1;
    }
  }
}
</style>
