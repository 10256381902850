<i18n>
{
  "de": {
    "statusHeader": "Rolle",
    "propertyStatusHeader": "Liegenschaftsrolle",
    "statusPillText": {
      "PLANNER": "PLANER",
      "REPORTER": "REPORTER",
      "ARCHIVED": "ARCHIVIERT"
    },
    "statusChangeText": {
      "PENDING_REPORTER": "Nächste Lizenzperiode: Reporter",
      "PENDING_ARCHIVED": "Nächste Lizenzperiode: Archiviert"
    }
  }
}
</i18n>

<template>
  <DetailListItem :title="$t('statusHeader')" expandable @expand="onModalOpen">
    <div class="content-wrapper">
      <div class="status-row">
        <div v-if="building.status" :class="`status-pill ${building.status}`">
          {{ $t(`statusPillText.${building.status}`) }}
        </div>
        <div v-if="isPending">({{ $t(`statusChangeText.${building.status_change_pending}`) }})</div>
      </div>
    </div>

    <template #modal>
      <Modal v-if="isModalOpen" class="status-modal" @close="onModalClose">
        <template #header>
          <h2>{{ $t('propertyStatusHeader') }}</h2>
        </template>
        <EditStatus :building="building" />
      </Modal>
    </template>
  </DetailListItem>
</template>

<script>
import ModalMixin from '@/components/shared/ModalMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import EditStatus from '@/components/building/edit/EditStatus.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'

export default {
  mixins: [
    //
    ModalMixin,
  ],

  components: {
    Modal,
    EditStatus,
    DetailListItem,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isPending() {
      return Boolean(this.building.status_change_pending)
    },
  },
}
</script>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xxs);

  & .status-row {
    display: flex;
    gap: var(--spacing-xs);

    & .status-pill {
      font-weight: 700;
      font-size: var(--font-xs);
      line-height: var(--font-xs);
      letter-spacing: 2px;
      padding: 6px 12px;
      border-radius: 13px;

      &.PLANNER {
        color: #1a9900;
        background-color: #1a99001a;
      }

      &.REPORTER {
        color: #008099;
        background-color: #0080991a;
      }

      &.ARCHIVED {
        color: #777777;
        background-color: #7777771a;
      }
    }
  }
}
</style>

<style lang="scss">
.status-modal {
  & .modal-wrapper {
    min-height: 400px !important;
  }
}
</style>
