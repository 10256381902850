<i18n>
{
	"de": {
    "energyUsage": "Energienutzung",
    "energyCarrier": "Energieträger",
    "ownUse": "Verwendung",
    "projectionByCarrier": "Absenkpfad nach Energieträger",
    "projectionByUsage": "Absenkpfad nach Energienutzung",
    "projectionByOwnUse": "Absenkpfad nach Verwendung",
    "scenario": "Szenario:"
	}
}
</i18n>

<template>
  <div class="c-projection-chart">
    <div class="selectors">
      <ScenarioPicker v-model="scenario" :portfolio="portfolio" :label="$t('scenario')" />
      <nav v-if="!hideSelector" class="toggle-buttons">
        <button
          v-if="projections.includes('usage')"
          type="button"
          :class="{ selected: projection === 'usage' }"
          @click="setProjection('usage')"
        >
          <span>{{ $t('energyUsage') }}</span>
        </button>
        <button
          v-if="projections.includes('carrier')"
          type="button"
          :class="{ selected: projection === 'carrier' }"
          @click="setProjection('carrier')"
        >
          <span>{{ $t('energyCarrier') }}</span>
        </button>
        <button
          v-if="projections.includes('own-use')"
          type="button"
          :class="{ selected: projection === 'own-use' }"
          @click="setProjection('own-use')"
        >
          <span>{{ $t('energyCarrier') }}</span>
        </button>
      </nav>
    </div>
    <div v-if="projection === 'usage'">
      <h3>{{ $t('projectionByUsage') }}</h3>
      <KpiByUsageChart
        :kpi-type="kpiType"
        class="kpi-chart"
        :scenario="scenario"
        :portfolio="portfolio"
        :query="{ building_id: this.building.id }"
      />
    </div>
    <div v-else-if="projection === 'carrier'">
      <h3>{{ $t('projectionByCarrier') }}</h3>
      <KpiByCarrierChart
        :kpi-type="kpiType"
        class="kpi-chart"
        :scenario="scenario"
        :portfolio="portfolio"
        :query="{ building_id: this.building.id }"
      />
    </div>
    <div v-else-if="projection === 'own-use'">
      <h3>{{ $t('projectionByOwnUse') }}</h3>
      <OwnUseChart :scenario="scenario" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
    </div>
  </div>
</template>

<script>
import ScenarioPicker from '@/components/shared/ScenarioPicker.vue'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart.vue'
import KpiByCarrierChart from '@/components/report/charts/KpiByCarrierChart.vue'
import OwnUseChart from '@/components/report/charts/OwnUseChart.vue'

export default {
  components: {
    ScenarioPicker,
    KpiByUsageChart,
    KpiByCarrierChart,
    OwnUseChart,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    projections: {
      type: Array,
      default: () => ['usage', 'carrier', 'own-use'],
    },
    defaultProjection: {
      type: String,
      default: 'usage',
    },
    kpiType: {
      type: String,
      default: null,
    },
    hideSelector: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scenario: this.portfolio?.scenarios?.[0],
      projection: this.defaultProjection,
    }
  },

  methods: {
    setProjection(projection) {
      this.projection = projection
    },
  },
}
</script>

<style lang="scss" scoped>
.c-projection-chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 445px;

  & h3 {
    width: 100%;
    text-align: center;
  }

  & .selectors {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-m);
  }
}
</style>

<style lang="scss">
.c-projection-chart {
  & .c-scenario-picker {
    margin-bottom: 0;
  }

  & .kpi-by-usage-chart,
  & .kpi-by-carrier-chart {
    padding: 0;
    border: none;

    & .chart-container {
      height: 300px;
    }
  }
}
</style>
