<i18n>
{
  "de": {
    "title": "Status & Standort"
  }
}
</i18n>

<template>
  <Card>
    <CardHeader :title="$t('title')" icon="cards/location" />
    <CardContent>
      <List divider>
        <BaseDataSummaryRow :building="building" />
        <ActiveSummaryRow :building="building" />
        <StatusSummaryRow :building="building" />
        <LocationSummaryRow :building="building" />
      </List>
    </CardContent>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'

import BaseDataSummaryRow from '@/components/building/overview/BaseDataSummaryRow.vue'
import ActiveSummaryRow from '@/components/building/overview/ActiveSummaryRow.vue'
import StatusSummaryRow from '@/components/building/overview/StatusSummaryRow.vue'
import LocationSummaryRow from '@/components/building/overview/LocationSummaryRow.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    BaseDataSummaryRow,
    ActiveSummaryRow,
    StatusSummaryRow,
    LocationSummaryRow,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },
}
</script>
