<i18n>
  {
    "de": {
      "enabled": "Ja",
      "disabled": "Nein",
      "zevLabel": "ZEV",
      "zevInfoBoxTitle": "Zusammenschluss zum Eigenverbrauch (ZEV)",
      "zevInfoBox": "Wenn der Zusammenschluss zum Eigenverbrauch ausgewählt ist, wird der PV-Strom auch für den Mieterstrom verwendet. Wenn nicht, wird PV-Strom lediglich für den Allgemeinstrom, Heizung und Warmwasser verwendet."
    }
  }
</i18n>

<template>
  <EditContainer
    class="edit-electricity"
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_ELECTRICITY')"
    @container="onFormEvent"
  >
    <ElectricityGridPicker
      class="energy-grid-picker"
      :edit="isEditing"
      :clearable="true"
      :carrier-options="carrierOptions"
      :gridSelectionId="gridSelectionId"
      :pvSelectionId="pvSelectionId"
      @gridInput="onGridChange"
      @pvInput="onPvChange"
    />
    <FormRow class="zev-row" :label="$t('zevLabel')" :info-title="$t('zevInfoBoxTitle')" :info-text="$t('zevInfoBox')">
      <CheckInput
        v-model="model.zev"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'

import ElectricityGridPicker from '@/components/shared/ElectricityGridPicker.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'

export default {
  mixins: [
    ComplexFormMixin,
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    ElectricityGridPicker,
    FormRow,
    CheckInput,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    carrierOptions() {
      return this.portfolio.energy_carriers
        .filter((ec) => ec.electricity_grid)
        .map((ec) => {
          return {
            id: ec.id,
            label: ec.default ? this.$t(`_energyCarriers.${ec.name}`) : ec.name,
            carrier: ec,
          }
        })
    },

    gridSelectionId() {
      if (this.model) {
        return this.model['electricity_grid_id']
      } else {
        return this.gridOptions[0].id
      }
    },

    pvSelectionId() {
      if (this.model) {
        return this.model['electricity_pv_id']
      } else {
        return this.gridOptions[0].id
      }
    },
  },

  watch: {
    building() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    resetModel() {
      this.model = {
        electricity_grid_id: this.building.initial_state.electricity_grid_id,
        electricity_pv_id: this.building.initial_state.electricity_pv_id,
        zev: this.building.initial_state.zev,
      }
    },

    async saveHandler() {
      await this.updateBuilding({
        id: this.building.id,
        initial_state: {
          electricity_grid_id: this.model['electricity_grid_id'],
          electricity_pv_id: this.model['electricity_pv_id'],
          zev: this.model['zev'],
        },
      })
    },

    onGridChange(electricityGridId) {
      this.model['electricity_grid_id'] = electricityGridId
      this.onInput()
    },

    onPvChange(electricityPvId) {
      this.model['electricity_pv_id'] = electricityPvId
      this.onInput()
    },
  },
}
</script>

<style>
.edit-electricity .limit-width {
  max-width: 150px;
  display: inline-block;
}
.edit-electricity .zev-row {
  margin-top: var(--spacing-m);
}
.edit-grid .form-label {
  width: 0;
}
</style>
