<i18n>
{
  "de": {
    "nameTitle": "Auswahl Stromprodukt",
    "gridTitle": "Netzbezug",
    "pvTitle": "PV-Anlage am Standort"
  }
}
</i18n>

<template>
  <div>
    <DetailList has-header class="grid-table">
      <template #header>
        <div></div>
        <div>{{ $t('nameTitle') }}</div>
      </template>

      <!--  -->
      <li>
        <div>
          <span>
            {{ $t('gridTitle') }}
          </span>
        </div>
        <v-select
          v-if="edit"
          v-model="gridSelection"
          :options="carrierOptions"
          :clearable="clearable"
          :reduce="(t) => t.id"
          style="margin-bottom: 0"
          @input="onGridInput"
        />
        <template v-if="!edit">
          <div v-if="grid === undefined || grid.id === 0" class="default">
            {{ $t('_energyCarriers.ELECTRICITY_GRID') }}
          </div>
          <div v-else>
            {{ selectedGridLabel }}
          </div>
        </template>
      </li>

      <!--  -->
      <li>
        <div>
          <span>
            {{ $t('pvTitle') }}
          </span>
        </div>
        <v-select
          v-if="edit"
          v-model="pvSelection"
          :options="carrierOptions"
          :clearable="clearable"
          :reduce="(t) => t.id"
          style="margin-bottom: 0"
          @input="onPvInput"
        />
        <template v-if="!edit">
          <div v-if="pv === undefined || pv.id === 0" class="default">{{ $t('_energyCarriers.ELECTRICITY_PV') }}</div>
          <div v-else>
            {{ selectedPvLabel }}
          </div>
        </template>
      </li>
    </DetailList>
  </div>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList.vue'

export default {
  components: {
    DetailList,
  },

  props: {
    carrierOptions: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    gridSelectionId: {
      type: Number,
    },
    pvSelectionId: {
      type: Number,
    },
  },

  data() {
    return {
      gridSelection: this.gridSelectionId,
      pvSelection: this.pvSelectionId,
    }
  },

  computed: {
    selectedGridLabel() {
      return this.grid === undefined ? null : this.grid.label
    },

    grid() {
      return this.carrierOptions.find((t) => t.id === this.gridSelection)
    },

    selectedPvLabel() {
      return this.pv === undefined ? null : this.pv.label
    },

    pv() {
      return this.carrierOptions.find((t) => t.id === this.pvSelection)
    },
  },

  watch: {
    gridSelectionId() {
      this.gridSelection = this.gridSelectionId
    },
    pvSelectionId() {
      this.pvSelection = this.pvSelectionId
    },
  },

  methods: {
    onGridInput() {
      const option =
        this.gridSelection === null ? null : this.carrierOptions.find((t) => t.id === this.gridSelection).id
      this.$emit('gridInput', option)
    },

    onPvInput() {
      const option = this.pvSelection === null ? null : this.carrierOptions.find((t) => t.id === this.pvSelection).id
      this.$emit('pvInput', option)
    },
  },
}
</script>

<style>
.energy-grid-picker .v-select {
  width: var(--default-column-width);
  margin-bottom: var(--spacing-m);
}
.energy-grid-picker .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>

<style scoped>
.default {
  color: darkgray;
}
</style>
