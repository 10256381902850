<i18n>
    {
      "de": {
        "performanceButton": "Performance",
        "energyButtons": {
          "energyButton": "Energie",
          "eeButton": "Endenergie",
          "peButton": "Primärenergie (PE)",
          "penrButton": "PE Fossil",
          "perButton": "PE Erneuerbar"
        },
        "emissionsButtons": {
          "emissionsButton": "Emissionen",
          "s12eButton": "Scope 1-2",
          "s123eButton": "Scope 1-3",
          "s1eButton": "Scope 1",
          "s2eButton": "Scope 2",
          "s3eButton": "Scope 3"
        },
        "performanceTitle": "Performance",
        "areaTitle": "Fläche",
        "endEnergyTitle": "Endenergiebedarf",
        "emissionsTitle": "Scope 1-2 Emissionen",
        "energyAreaTitle": "Energiebezugsfläche"
      }
    }
    </i18n>

<template>
  <div class="c-building-kpis">
    <!-- KPI types toggle -->
    <div class="buttons-container">
      <nav class="round-buttons">
        <button type="button" :class="{ selected: kpiMode === 'performance' }" @click="setKpiMode('performance')">
          <span>{{ $t('performanceButton') }}</span>
        </button>
        <!-- TODO: Add Energy and Emissions KPIshere later -->
        <!-- <button type="button" :class="{ selected: kpiMode === 'energy' }" @click="setKpiMode('energy')">
          <span>{{ $t('energyButtons.energyButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiMode === 'emissions' }" @click="setKpiMode('emissions')">
          <span>{{ $t('emissionsButtons.emissionsButton') }}</span>
        </button> -->
      </nav>
    </div>

    <!-- KPI charts -->
    <div class="kpis-container">
      <!-- Performance -->
      <div v-if="kpiMode === 'performance'" :key="`performanceTitle`" class="tab tab--performance">
        <!-- Bubble chart -->
        <div>
          <BubbleSummaryChart key="buildingbubble" :building="building" :chart-data="chartData" class="bubble-chart" />
          <div class="kpis">
            <KpiBox
              :label="$t('areaTitle')"
              :value="formatNumber(totalEnergyArea)"
              valueUnit="m²"
              :relativeValue="$t('energyAreaTitle')"
            />
            <KpiBox
              :label="$t('endEnergyTitle')"
              :value="formatNumber(totalEndEnergy / 1000, 1)"
              valueUnit="MWh"
              :relativeValue="formatNumber(totalEndEnergy / totalEnergyArea, 1)"
              relativeValueUnit="kWh/m²"
            />
            <KpiBox
              :label="$t('emissionsTitle')"
              :value="formatNumber(totalEmissions / 1000, 1)"
              valueUnit="t CO₂"
              :relativeValue="formatNumber(totalEmissions / totalEnergyArea, 1)"
              relativeValueUnit="kg CO₂/m²"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BubbleSummaryChart from '@/components/building/kpi/BubbleSummaryChart.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'

export default {
  components: {
    BubbleSummaryChart,
    KpiBox,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: 'vertical',
    },
  },

  computed: {
    // performance vs energy, emissions
    kpiMode() {
      return this.$store.state.ui.buildingKpiMode
    },

    totalEnergyArea() {
      const sum = this.building.initial_state.zones.zones.reduce((pv, cv) => {
        if (cv.energy_area.deduced) {
          return pv + parseFloat(cv.energy_area.deduced)
        } else if (cv.energy_area) {
          return pv + parseFloat(cv.energy_area)
        } else {
          return pv
        }
      }, 0)
      if (Number.isNaN(sum)) {
        return undefined
      }
      return sum
    },

    totalEndEnergy() {
      return this.building.kpi.ee
    },

    totalEmissions() {
      return this.building.kpi.s12e
    },
  },

  created() {
    // performance vs energy, emissions
    const kpiMode = localStorage.getItem('buildingKpiMode')
    if (kpiMode) {
      this.$store.dispatch('ui/setBuildingKpiMode', kpiMode)
    }
  },

  methods: {
    //
    setKpiMode(mode) {
      this.$store.dispatch('ui/setBuildingKpiMode', mode)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-building-kpis {
  position: relative;
  overflow: hidden;

  & .kpis-container {
    height: calc(100% - 65px);
    overflow-y: auto;
    overflow-x: hidden;

    & .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-l);
      overflow: hidden;
      padding: 20px var(--spacing-s) 160px;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        width: calc(min(100%, 500px));
      }

      &.tab--performance {
        width: 100%;
        min-height: 100%;
        padding: 20px var(--spacing-s);
        overflow-y: auto;

        @media screen and (max-width: 1280px) {
          height: auto;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          gap: var(--spacing-l);
          width: 100%;
        }

        & .kpis {
          flex: none;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: var(--spacing-m);
          padding: 0 var(--spacing-s);
        }
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    overflow-x: auto;
    height: 65px;
  }
}
</style>

<style lang="scss">
.c-building-kpis {
  & .histogram-chart > .chart-container {
    height: 200px;
  }

  & .data-quality-chart > .chart-container {
    height: 300px;
  }

  & .pie-chart > .chart-container {
    height: 200px;
  }

  & h3 {
    text-align: center;
  }
}
</style>
