<template>
  <div :class="`cui-card-header ${className}`">
    <div class="cui-card-header__title">
      <img v-if="icon" :src="iconSrc" :alt="title" class="cui-card-header__icon" />
      <slot name="prefix" class="cui-card-header__prefix"></slot>
      <slot name="title">
        <span v-if="title">
          {{ title }}
        </span>
      </slot>
    </div>
    <slot name="action" class="cui-card-header__action"></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    className: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconSrc() {
      // If full path with extension used
      if (/\.[a-zA-Z0-9]+$/.test(this.icon)) return this.icon
      // If short name used
      else return `/icons/${this.icon}.svg`
    },
  },
}
</script>

<style lang="scss" scoped>
.cui-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xs);
  width: 100%;
  height: 64px;
  padding: 20px 12px;
  border-bottom: var(--box-border);
  border-radius: var(--box-radius) var(--box-radius) 0 0;
  background-color: #fff;

  & .cui-card-header__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  & .cui-card-header__icon {
    pointer-events: none;
  }
}
</style>
