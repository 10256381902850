<i18n>
{
  "de": {
    "s12eTitle": "Scope 1-2 Emissionen",
    "energyEfficiencyTitle": "Energieeffizienz (Primärenergie)",
    "heatingEfficiencyTitle": "Gebäudehülle (Raumwärme)",
    "peRatioExplanation": "Gesamtenergieeffizienz nach SIA 2031:2016",
    "rhRatioExplanation": "Effizienzklasse Gebäudehülle nach SIA 2031:2016"
  }
}
</i18n>

<template>
  <div class="c-kpi-report-card">
    <!-- Emissions -->
    <div>
      <div class="label">{{ $t('s12eTitle') }}</div>
      <div class="value">
        <div>
          <span class="bold">
            {{ formatNumber(s12eValue, 1) }}
          </span>
          <span> kg CO₂/m² </span>
        </div>
        <!-- <EnergyLabel :value="s12eRatio" /> -->
      </div>
    </div>

    <!-- Energy -->
    <div>
      <div class="label">{{ $t('energyEfficiencyTitle') }}</div>
      <div class="value">
        <div>
          <span class="bold">
            {{ formatNumber(energyEfficiencyValue, 1) }}
          </span>
          <span> kWh/m² </span>
        </div>
        <VPopover trigger="hover" placement="right">
          <EnergyLabel :value="energyEfficiencyRatio" />
          <template slot="popover">
            <main>{{ $t('peRatioExplanation') }}</main>
          </template>
        </VPopover>
      </div>
    </div>

    <!-- Heating -->
    <div>
      <div class="label">{{ $t('heatingEfficiencyTitle') }}</div>
      <div class="value">
        <div>
          <span class="bold">
            {{ formatNumber(heatingEfficiencyValue, 1) }}
          </span>
          <span> kWh/m² </span>
        </div>
        <VPopover trigger="hover" placement="right">
          <EnergyLabel :value="heatingEfficiencyRatio" />
          <template slot="popover">
            <main>{{ $t('rhRatioExplanation') }}</main>
          </template>
        </VPopover>
      </div>
    </div>
  </div>
</template>

<script>
import { VPopover } from 'v-tooltip'

import EnergyLabel from '@/components/shared/EnergyLabel.vue'

export default {
  components: {
    EnergyLabel,
    VPopover,
  },

  props: {
    s12eValue: {
      type: Number,
      required: true,
    },
    energyEfficiencyValue: {
      type: Number,
      required: true,
    },
    heatingEfficiencyValue: {
      type: Number,
      required: true,
    },
    // s12eRatio: {
    //   type: Number,
    //   required: true,
    // },
    energyEfficiencyRatio: {
      type: Number,
      required: true,
    },
    heatingEfficiencyRatio: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-kpi-report-card {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xl);
  min-width: 280px;
  max-width: 30%;
  padding: var(--spacing-xxl) var(--spacing-l);
  font-size: var(--font-s);

  & .label {
    font-weight: 600;
    margin-bottom: var(--spacing-xxs);
  }

  & .value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .bold {
      font-weight: 600;
      margin-right: var(--spacing-xxs);
    }
  }
}
</style>
