<i18n>
{
  "de": {
    "photovoltaic": "Photovoltaik",
    "pvProduction": "PV-Produktion",
    "ownUse": "Eigenverbrauch",
    "measurementData": "Messdaten"
  }
}
</i18n>

<template>
  <Modal class="c-pv-kpi-modal" :width="800" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('photovoltaic') }}</h2>
    </template>

    <div class="content">
      <div class="cards-row">
        <div class="kpi-values card">
          <h3>{{ $t('pvProduction') }}</h3>
          <KpiBox
            :value="formatNumber((building?.kpi?.el_production || 0) / 1000, 1)"
            valueUnit="MWh"
            :relativeValue="`${$t('ownUse')}: ${kpiData.find((kpi) => kpi.indicator === 'el_ownuse').share}`"
            relativeValueUnit="%"
          />
        </div>
        <div class="pv-share-chart card">
          <h3>{{ $t('pvProduction') }}</h3>
          <DoughnutChart :chart-data="chartData" :options="chartOptions" :expandable="false" />
        </div>
      </div>

      <!-- Meters -->
      <div class="measurement-data-chart card">
        <h3>{{ $t('measurementData') }}</h3>
        <MeasuredKpiChart kpi-type="ee" :portfolio="portfolio" :query="{ building_id: this.building.id }" />
      </div>

      <br />

      <!-- Projections 1 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['own-use']"
          default-projection="own-use"
          hide-selector
        />
      </div>

      <br />

      <!-- Projections 2 -->
      <div class="card">
        <ProjectionChart
          :building="building"
          :portfolio="portfolio"
          :projections="['own-use']"
          default-projection="own-use"
          hide-selector
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import Modal from '@/components/shared/Modal.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import ProjectionChart from '@/components/building/kpi/ProjectionChart.vue'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    Modal,
    KpiBox,
    DoughnutChart,
    MeasuredKpiChart,
    ProjectionChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    kpiData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { precision: 1, unit: 'MWh', label: item.label }),
            },
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pv-kpi-modal {
  display: flex;
  flex-direction: column;

  & .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    & .cards-row {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    & .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .kpi-values {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      flex: 1;
      text-align: center;
    }

    & .selectors {
      width: 100%;
      display: flex;
    }

    & .pv-share-chart,
    & .measurement-data-chart {
      flex: 1;
    }
  }
}
</style>
