<i18n>
  {
    "de": {
      "details": "Details"
    }
  }
  </i18n>

<template>
  <Card className="c-kpi-overview">
    <CardHeader :title="title" :icon="titleIcon">
      <template v-if="showDetails" #action>
        <Button :text="$t('details')" @click="onDetailsClick" />
      </template>
    </CardHeader>

    <CardContent>
      <div class="primary-section">
        <div class="values">
          <slot name="values" />
        </div>
        <div class="chart">
          <slot name="chart" />
        </div>
      </div>

      <Divider v-if="showDetails" />

      <div v-if="showDetails" class="secondary-section">
        <slot name="table" />
      </div>
    </CardContent>

    <slot name="modal" />
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import Button from '@/components/cui/inputs/Button.vue'
import Divider from '@/components/cui/data-display/Divider.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    Button,
    Divider,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    titleIcon: {
      type: String,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    onDetailsClick() {
      this.$emit('details-click')
    },
  },
}
</script>

<style lang="scss" scoped>
.c-kpi-overview {
  & .primary-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
    width: 100%;
    height: 128px;

    & .values {
      padding: var(--spacing-s);
      width: 50%;
    }

    & .chart {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
    }
  }

  & .secondary-section {
    padding: var(--spacing-m) var(--spacing-s);
  }
}
</style>

<style lang="scss">
.c-kpi-overview {
  & .cui-button {
    padding: 4px 20px;
    border-radius: 16px;
  }

  & .c-kpi-box {
    & .value {
      font-size: 28px;
      line-height: 24px;
      margin: 3px 0;
    }
  }

  // Charts specific sizing ----
  & .kpi-doughnut-chart,
  & .emissions-doughnut-chart,
  & .pv-share-chart {
    & .chart-container {
      height: 120px;
    }
  }

  & .energy-carrier-chart {
    & .chart-container {
      height: 180px;
    }
  }

  & .measurement-data-chart {
    & .chart-container {
      height: 300px;
    }

    & .measured-kpi-chart {
      padding: 0;
      border: none;
    }
  }

  & .own-use-chart {
    padding: 0;
    border: none;

    & .chart-container {
      height: 300px;
    }
  }
  // ----------------------------

  & .c-scenario-picker {
    margin-bottom: 0 !important;
  }

  & .detail-list {
    & > ul {
      border: none;
    }

    & > ul > li {
      font-size: var(--font-s);
      line-height: 16px;
      padding: 4px 0;
      border: none;

      &.header * {
        line-height: 18px;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }
}
</style>
